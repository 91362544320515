<template>
  <ModalModule
    v-if="software"
    v-model="activeModal"
    :title="
      $textDictionary['downloadCenterSoftware.modalTitle'] ??
      'downloadCenterSoftware.modalTitle'
    "
    close-icon-text="close"
    :esc-to-close="true"
    :click-to-close="false"
    :is-full-screen="true"
    @abort="emits('closeModal')"
    @after-close="emits('afterCloseModal')"
  >
    <div v-if="currentStep === 'overview'" class="row">
      <div class="d-lg-flex flex-lg-row-reverse">
        <div class="col-lg-3 text-lg-end col-sm-12 pb-4">
          <ShareButton
            :url="shareUrl ?? ''"
            :title="shareTitle ?? ''"
            :share-text="
              $textDictionary['softwareCenter.share.label'] ?? 'Share software'
            "
            :copied-text="
              $textDictionary['downloadCenter.copied.label'] ??
              'Copied to Clipboard'
            "
            :copy-text="
              $textDictionary['downloadCenter.copy.label'] ??
              'Copy download link'
            "
            @click="handleOnShareClick"
          />
        </div>
        <div class="col-lg-9 col-sm-12">
          <h2>{{ software.name }}</h2>
          <MetaList class="mb-4" :items="metaListItems" />
          <div v-if="software.abstract" class="row software-abstract">
            <div
              id="abstractTextHeight"
              :class="showShortedText ? 'rte-text shorted' : 'rte-text'"
              v-html="software.abstract"
            />
            <div
              v-if="showShortedText"
              class="button-container text-center pt-3"
            >
              <Button
                class="pt-4"
                style-type="link"
                icon-type="icon-left"
                icon-name="arrow-down"
                @click="showShortedText = false"
              >
                {{
                  $textDictionary['downloadCenterSoftware.showFullText'] ??
                  'downloadCenterSoftware.showFullText'
                }}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6 col-sm-12">
          <template
            v-if="software?.supportedOs && !(software?.supportedOs.length <= 1)"
          >
            <p class="fw-bold">
              {{
                $textDictionary[
                  'downloadCenterSoftware.operationSystemLabel'
                ] ?? 'downloadCenterSoftware.operationSystemLabel'
              }}
            </p>
            <FormKit type="form" :actions="false">
              <FormKit
                v-model="selectedOs"
                type="select"
                name="sort-by"
                :options="softwareOsOptions"
                outer-class="is-secondary"
              >
              </FormKit>
            </FormKit>
          </template>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <div class="custom-table rte-text">
            <CustomTable>
              <table style="width: 100%; min-width: 800px">
                <thead>
                  <tr>
                    <th scope="col">
                      {{
                        $textDictionary[
                          'downloadCenterSoftware.operationSystemTableLabel'
                        ] ?? 'downloadCenterSoftware.operationSystemTableLabel'
                      }}
                    </th>
                    <th scope="col">
                      {{
                        $textDictionary[
                          'downloadCenterSoftware.formatTableLabel'
                        ] ?? 'downloadCenterSoftware.formatTableLabel'
                      }}
                    </th>
                    <th v-if="hasFurtherDownloads" scope="col">
                      {{
                        $textDictionary[
                          'downloadCenterSoftware.furtherDownloadsTableLabel'
                        ] ?? 'downloadCenterSoftware.furtherDownloadsTableLabel'
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="asset of softwareLinks" :key="asset.os.name">
                    <tr>
                      <td class="nowrap" style="max-width: 500px">
                        <Button
                          class="custom-button-link"
                          style-type="link"
                          icon-type="icon-left"
                          :icon-name="
                            asset.os.icon === 'macOs' ? 'mac' : asset.os.icon
                          "
                          @click="selectDownload(asset)"
                        >
                          {{ asset.os.name }}
                        </Button>
                      </td>
                      <td class="nowrap">
                        <Button
                          style-type="link"
                          class="custom-button-link"
                          icon-type="icon-left"
                          :icon-name="asset.format.icon"
                          @click="selectDownload(asset)"
                        >
                          {{ asset.format.fileExtension }} </Button
                        >,
                        {{ asset.format.readableFilesize }}
                      </td>
                      <td v-if="hasFurtherDownloads">
                        <template
                          v-for="singleDownload of asset.furtherDownloads"
                          :key="singleDownload.label"
                        >
                          <Button
                            style-type="link"
                            class="custom-button-link"
                            icon-type="icon-left"
                            :icon-name="asset.format.icon"
                            @click="
                              forceDownloadAndShowSuccess(singleDownload.uri)
                            "
                          >
                            {{ singleDownload.label }}
                          </Button>
                          <br />
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="currentStep === 'form' && formUrl">
      <div v-if="software.abstract" class="row">
        <div class="col-lg-8 rte-text" v-html="software.abstract" />
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <PardotFormIframe :form-url="formUrl" @success="handleFormSuccess" />
        </div>
      </div>
    </div>

    <DownloadCenterModalSuccessBlock
      v-else-if="currentStep === 'success'"
      :download-link="selectedDownloadLink"
      :hande-back-to-overview="handeBackToOverview"
    />
  </ModalModule>
  <ModalModule
    v-else
    v-model="activeModal"
    title="Download Software"
    close-icon-text="close"
    :esc-to-close="true"
    :click-to-close="false"
    @abort="emits('closeModal')"
    @after-close="emits('afterCloseModal')"
  >
    <LibRichTextRenderer :rich-text="noDownloadFoundHint" />
  </ModalModule>
</template>

<script setup lang="ts">
import {
  Button,
  ModalModule,
  ShareButton,
  MetaList,
  Table as CustomTable
} from '@hypercodestudio/basler-components';
import PardotFormIframe from '~/components/downloads/PardotFormIframe.vue';
import type { SoftwareEntryModel } from '~/lib/DownloadService/model';
import type { MetaItem } from '@hypercodestudio/basler-components/dist/components/elements/MetaList.vue';
import { forceDownload } from '~/utils/forceDownload';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import DownloadCenterModalSuccessBlock from '~/components/downloads/DownloadCenterModalSuccessBlock.vue';
import { useSoftwareOsLabel } from '~/lib/DownloadService/helper/useSoftwareOsLabel';
import { ref } from 'vue';

const {
  $textDictionary,
  $dictionary,
  $globalPageSettings,
  $currentEnvName,
  $analytics
} = useNuxtApp();
const logger = useLogger();
const locale = useLocale();
const url = useRequestURL();

interface Props {
  software?: SoftwareEntryModel | null;
  open?: boolean;
  preferredOs?: string;
}

const props = defineProps<Props>();

const emits = defineEmits<{
  /**
   * Once the modal was close via the "close" button (is not triggered via "esc").
   */
  closeModal: [];
  /**
   * After the modal was closed (emits always, also while closing via "esc").
   */
  afterCloseModal: [];
}>();

type Steps = 'overview' | 'form' | 'success';

const currentStep = ref<Steps>('overview');

const noDownloadFoundHint = $dictionary.value['downloadCenter.modal.notFound'];

const activeModal = ref(props.open);

const showShortedText = ref(false);

const shareUrl = computed(() =>
  props.software?.id
    ? buildUrlString(
        locale.value,
        `${
          $globalPageSettings.value?.softwareCenterPage?.metadata?.slug ?? ''
        }/${props.software.id}`
      )
    : undefined
);

const shareTitle = computed(() => props.software?.name);

const hasFurtherDownloads = computed(() =>
  softwareLinks.value.some((asset) => asset.furtherDownloads.length > 0)
);

const metaListItems = computed((): MetaItem[] => {
  const items: MetaItem[] = [];
  if (props.software?.dateYear) {
    items.push({
      text: props.software?.dateYear,
      iconName: 'calendar'
    });
  }

  if (props.software?.softwareVersion) {
    items.push({
      text: props.software?.softwareVersion,
      iconName: 'hashtag'
    });
  }

  return items;
});

type FurtherDownloads = {
  label?: string;
  uri: string;
};

function showShortedTextFunc() {
  const element = document.getElementById('abstractTextHeight');
  if (element && element.offsetHeight) {
    return element.offsetHeight > 260;
  }
  return false;
}

function findFurtherDownloads(
  supportedOs?: ReadonlyArray<string>,
  platform?: ReadonlyArray<string>
): FurtherDownloads[] {
  const filteredDownloads = props.software?.groupAssets?.filter((download) => {
    if (
      download.supportedOs?.includes(supportedOs?.[0] ?? '') &&
      download.platform?.includes(platform?.[0] ?? '') &&
      !download.isMainAsset
    ) {
      return download;
    }
  });
  const furtherDownloads: FurtherDownloads[] = [];
  if (filteredDownloads) {
    for (const download of filteredDownloads) {
      if (!download.mediaLink) {
        logger.warn(
          'software further download has no media link - skipped',
          download
        );

        continue;
      }

      furtherDownloads.push({
        label: download.name,
        uri: download.mediaLink
      });
    }
  }

  return furtherDownloads;
}

onMounted(() => {
  showShortedText.value = showShortedTextFunc();
});

watch(
  () => props.open,
  () => {
    activeModal.value = props.open;

    if (!props.open) {
      // reset download state on close
      currentStep.value = 'overview';
    }
  }
);

const { isMacOS, isAndroid, isWindows, userAgent } = useDevice();

const userOs = computed<string | undefined>(() => {
  if (isWindows) {
    return 'windows';
  }
  if (isMacOS) {
    return 'macOs';
  }
  if (isAndroid) {
    return 'android';
  }
  if (userAgent.toLowerCase().includes('linux')) {
    return 'linux';
  }
});

const softwareOsOptions = computed(() => {
  const items = [];

  if (props.software?.supportedOs) {
    for (const [value, label] of Object.entries(props.software?.supportedOs)) {
      items.push({
        value: label,
        label: useSoftwareOsLabel(label)
      });
    }
  }
  return items;
});

const initialSelectedOs = computed(() => {
  if (
    props.preferredOs &&
    props.software?.supportedOs?.includes(props.preferredOs)
  ) {
    return props.preferredOs;
  }
  if (userOs.value && props.software?.supportedOs?.includes(userOs.value)) {
    return userOs.value;
  }

  return props.software?.supportedOs?.[0];
});

const selectedOs = ref(initialSelectedOs.value);

watch(initialSelectedOs, () => {
  if (initialSelectedOs.value) {
    selectedOs.value = initialSelectedOs.value;
  }
});

type SoftwareLinks = {
  formRequired: boolean;
  os: {
    icon?: string;
    name: string;
    uri?: string;
  };
  format: {
    icon: 'download';
    fileExtension?: string;
    readableFilesize?: string;
    uri: string;
  };
  furtherDownloads: FurtherDownloads[];
};

const softwareLinks = computed<SoftwareLinks[]>(() => {
  const groupedAssets: SoftwareLinks[] = [];
  if (!props.software) {
    return [];
  }

  for (const entry of props.software.groupAssets!) {
    if (!entry.supportedOs) {
      groupedAssets.push({
        formRequired: entry.formRequired ?? false,
        os: {
          icon: undefined,
          name: entry.name ?? 'File',
          uri: entry.mediaLink
        },
        format: {
          icon: 'download',
          fileExtension: entry.fileExtension,
          readableFilesize: entry.readableFilesize,
          uri: entry.mediaLink!
        },
        furtherDownloads: findFurtherDownloads(
          entry.supportedOs,
          entry.platform
        )
      });
    }
    if (
      selectedOs.value &&
      entry.isMainAsset &&
      entry.supportedOs?.includes(selectedOs.value)
    ) {
      // every main software entry must have a link to actually work
      if (!entry.mediaLink) {
        logger.warn('software main asset has no media link - skipped', entry);

        continue;
      }

      groupedAssets.push({
        formRequired: entry.formRequired ?? false,
        os: {
          icon: entry.supportedOs?.[0],
          name: entry.name ?? 'File',
          uri: entry.mediaLink
        },
        format: {
          icon: 'download',
          fileExtension: entry.fileExtension,
          readableFilesize: entry.readableFilesize,
          uri: entry.mediaLink
        },
        furtherDownloads: findFurtherDownloads(
          entry.supportedOs,
          entry.platform
        )
      });
    }
  }

  return groupedAssets;
});

// e.g. 'https://info.baslerweb.com/l/73192/2020-02-24/98pcbf';
const formUrl = computed(() => {
  const urlString = props.software?.downloadCategoryFormUrl
    ? props.software?.downloadCategoryFormUrl
    : $globalPageSettings.value?.softwarePardotFormUrl;

  if (!urlString) {
    return;
  }

  try {
    const formUrl = new URL(urlString);
    formUrl.searchParams.append('download', 'software');
    formUrl.searchParams.append('domain', $currentEnvName.value);

    const filename = props.software?.name;
    if (filename) {
      formUrl.searchParams.append('filename', filename);
    }

    return formUrl.toString();
  } catch (e) {
    logger.error('could not create new url from pardot url', e);
  }
});

const selectedDownload = ref<string | undefined>();
const selectedDownloadLink = computed<HyperlinkInterface>(() => {
  return {
    uri: selectedDownload.value ?? '#',
    target: '_self',
    external: false
  } satisfies HyperlinkInterface;
});

function forceDownloadAndShowSuccess(downloadUrl: string): void {
  currentStep.value = 'success';
  forceDownload(downloadUrl);
}

function selectDownload(download: SoftwareLinks): void {
  selectedDownload.value = download.format.uri;

  if (download.formRequired) {
    currentStep.value = 'form';

    return;
  }

  forceDownloadAndShowSuccess(download.format.uri);
}

function handleFormSuccess(): void {
  currentStep.value = 'success';
  $analytics?.pushToDataLayer({
    event: 'formSent',
    goalPage: '/downloadform/sent',
    page_location: url.href
  });

  if (selectedDownload.value) {
    forceDownload(selectedDownload.value);
  }
}

function handleOnShareClick(): void {
  if (props.software) {
    $analytics?.pushToDataLayer({
      event: 'share',
      ecommerce: {
        method: 'Software Center',
        item_id: `${props.software.id} | ${props.software.name}`
      }
    });
  }
}

function handeBackToOverview(): void {
  currentStep.value = 'overview';
}
</script>

<style>
.custom-button-link {
  .button__content {
    font-family: var(--font-family-body);
    font-weight: var(--font-weight-body);
    text-decoration: underline;
    padding: 0;
    text-wrap: wrap;
    text-align: left;
  }
}

.software-abstract {
  position: relative;
  .shorted {
    max-height: 260px;
    overflow: hidden;
  }
  .button-container {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    position: absolute;
    bottom: 0;
  }
}
</style>
