import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _01_locale_client_slenZJp2bM from "/app/plugins/01.locale.client.ts";
import _02_globalPageSettings_client_LuD87ZzqUM from "/app/plugins/02.globalPageSettings.client.ts";
import session_client_uxRghRzxlC from "/app/plugins/session.client.ts";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_iGLjBbor9q from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/app/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_logger_client_lOQlgMMdSS from "/app/plugins/00.logger.client.ts";
import _03_pageSettings_KVkeWGaMft from "/app/plugins/03.pageSettings.ts";
import _04_dictionary_client_djwFXbxJNF from "/app/plugins/04.dictionary.client.ts";
import _05_modals_UsgJZOtfhV from "/app/plugins/05.modals.ts";
import _06_env_wRMOOHtLOR from "/app/plugins/06.env.ts";
import _09_pinia_wSpJwDhQOk from "/app/plugins/09.pinia.ts";
import _10_shop_hCdKrCZd8x from "/app/plugins/10.shop.ts";
import _11_audiences_S2jEQB3riu from "/app/plugins/11.audiences.ts";
import _12_secondaryNavigation_P5BWoituVh from "/app/plugins/12.secondaryNavigation.ts";
import _30_analytics_client_ugBNo80Zou from "/app/plugins/30.analytics.client.ts";
import _40_breakpoints_client_nZT3O58Z6i from "/app/plugins/40.breakpoints.client.ts";
import _41_globalCartStore_client_Cs8E7R70Ur from "/app/plugins/41.globalCartStore.client.ts";
import _50_vue_toastification_wXISWCi8Q3 from "/app/plugins/50.vue-toastification.ts";
import _51_primevue_bj7noxYjMx from "/app/plugins/51.primevue.ts";
import _52_tippy_XWA4Y1XgPr from "/app/plugins/52.tippy.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _01_locale_client_slenZJp2bM,
  _02_globalPageSettings_client_LuD87ZzqUM,
  session_client_uxRghRzxlC,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_ghbUAjaD3n,
  plugin_iGLjBbor9q,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_UciE0i6zes,
  _00_logger_client_lOQlgMMdSS,
  _03_pageSettings_KVkeWGaMft,
  _04_dictionary_client_djwFXbxJNF,
  _05_modals_UsgJZOtfhV,
  _06_env_wRMOOHtLOR,
  _09_pinia_wSpJwDhQOk,
  _10_shop_hCdKrCZd8x,
  _11_audiences_S2jEQB3riu,
  _12_secondaryNavigation_P5BWoituVh,
  _30_analytics_client_ugBNo80Zou,
  _40_breakpoints_client_nZT3O58Z6i,
  _41_globalCartStore_client_Cs8E7R70Ur,
  _50_vue_toastification_wXISWCi8Q3,
  _51_primevue_bj7noxYjMx,
  _52_tippy_XWA4Y1XgPr
]