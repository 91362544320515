import type { Product } from '@hypercodestudio/basler-components/dist/models/product';
import type { ProductCardProps } from '@hypercodestudio/basler-components/dist/components/modules/ProductCard.vue';
import type { Attribute } from '@hypercodestudio/basler-components/dist/components/modules/ProductDetailFeatures.vue';
import type { MediaItemInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/Media.vue';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import {
  getCustomAttributeArrayValue,
  getCustomAttributeValue,
  getCustomAttributeFirstValue
} from './getCustomAttributeValueFromMetadataCode';
import { getProductImage } from './getProductImage';
import type { GenerateImageObjectOptions } from './generateImageObject';
import type {
  AttributeSetFragment,
  CommonProductFragment,
  ConfigurableProduct,
  ConfigurableVariant,
  CrossSellProductFragment,
  CustomAttribute,
  CustomAttributeFragment,
  ProductAttributeFilterInput,
  ProductDetailFragment
} from '~/lib/Shop/generated/schema';
import {
  BRAND,
  BRAND_DATA,
  CAMERA_PRODUCT_LINE,
  CAMERA_SERIES_CODE,
  CAMERA_CATEGORY,
  COMING_SOON_LABEL,
  CONFORMITY_ENTITY_DATA,
  DISCONTINUATION_ADDITIONAL_LINKS_DATA,
  DISCONTINUATION_STATUS,
  DISCONTINUATION_STATUS_DISCONTINUED,
  DISTRIBUTORS,
  DISTRIBUTORS_DATA,
  CHANNEL_PARTNERS,
  CHANNEL_PARTNERS_DATA,
  DOCUMENTATION_AVAILABLE,
  DOCUMENTATION_URL,
  DOCUMENTATION_URL_SLUG,
  ECOMMERCE_SHOW_ADDTOCARTBUTTON,
  ECOMMERCE_SHOW_REQUESTCARTBUTTON,
  FAMILY,
  FASTDELIVERY_AVAILABLE,
  HARDWARE_FEATURES_DATA,
  INTERFACE,
  IS_COMING_SOON,
  IS_IN_BUNDLE,
  IS_NEW,
  MODEL_NAME,
  SEO_FOLLOW,
  SEO_HEADLINE,
  SEO_INDEX,
  SHIPPING_TIME_CODE,
  SHOW_PRICE,
  SHOW_VSC,
  STATE_IN_STOCK,
  TEASER_TEXT,
  TECHNICAL_DRAWING,
  TESTCAMERA_AVAILABLE,
  VARIANT_BASE_META_DESCRIPTION,
  VARIANT_BASE_TEXT,
  ACCESSORY_CATEGORY,
  ACCESSORY_TYPE,
  PDP_ACTION_BOX_LINKS_DATA
} from '~/utils/shop/constants';
import {
  createHighlightedSpecifications,
  createSpecifications,
  extractLabelValueFromCustomAttribute,
  filteredSpecificationMappings,
  flipAttributeSetMapping
} from '~/utils/shop/createSpecifications';
import { isDefined } from '~/utils/guards/isDefined';
import { createForwardedValues } from '~/utils/shop/createForwardedValues';
import type { ProductFiltersModel } from '~/stores/productFilterStore/model';
import { createShippingTimeText } from '~/utils/shop/createShippingTimeText';
import { createPriceText } from '~/utils/shop/createPriceText';
import type { PdpConfiguration } from '~/composables/usePdpConfiguration';
import type { LOCALE_CODE } from '~/lib/ContentfulService';
import { cloneDeep } from '~/utils/cloneDeep';
import { failSafeParseJSON } from '~/utils/failSafeParseJSON';
import type { Group } from '@hypercodestudio/basler-components/dist/components/modules/ProductSpecs.vue';
import type { LocationQuery } from 'vue-router';

export type RelatedProductGroupModel = {
  title: string;
  groupTitle: string;
  queryFilter: ProductAttributeFilterInput;
  products: CommonProductFragment[];
};

type ProductFeatureAttributeModel = {
  label?: { value: string };
  link: { value: string };
  featuregruppe: { value: string };
  magento_option_id: string;
};

export type ProductEnrichmentRules = {
  productSkus?: string[];
  productUrlKeys?: string[];
  productSeries?: string[];
  cameraProductFamily?: string[];
  productAccessoryCategories?: string[];
  accessoryType?: string[];
  productCategories?: string[];
};

type PdpActionBoxLink = {
  ctf_dict_key: {
    label: string;
    value: string[];
  };
  ctf_entity_id: {
    label: string;
    value: string[];
  };
  label: {
    label: string;
    value: string[];
  };
  magento_option_id: string;
  external_url: {
    label: string;
    value: string[];
  };
};

/**
 * XXX: Create a model for List Use and one for PDP use.
 *  They might share the same base object to reuse properties.
 */
export class DecoratedProduct {
  // XXX: all properties should be public and readonly, remove explicit getters.
  public readonly id: string;
  public readonly sku: string | undefined;
  public readonly urlKey: string | undefined;
  /**
   * The "complete" name (might including series. etc.)
   *
   * @example "Stereo Camera rc_visard 160c"
   */
  public readonly name: string | undefined;
  /**
   * The shipping time in days.
   */
  public readonly shippingTime: number;
  public readonly shippingTimeText: string | undefined;
  public readonly modelName: string | undefined;
  public readonly category: string | undefined;
  public readonly categoryId: string | undefined;
  public readonly cameraCategory: string | undefined;
  public readonly productBrand: string | undefined;
  public readonly productFeaturesAttributes: ReadonlyArray<ProductFeatureAttributeModel>;
  public readonly distributors: string[] | undefined; //deprecated
  public readonly distributorsData: string[] | undefined; //deprecated
  public readonly channelPartners: string[] | undefined;
  public readonly channelPartnersData: {
    deepurl: {
      label: string;
      value: string[];
    };
    image: {
      label: string;
      value: string[];
    };
    label: {
      label: string;
      value: string[];
    };
    logourl: {
      label: string;
      value: string[];
    };
    magento_option_id: string;
  }[];
  public readonly productDescription: string | undefined;
  public readonly productDocumentationLink: string | undefined;
  public readonly productDocumentationLinkSlug: string | undefined;
  public readonly productTeaserText: string | undefined;
  public readonly seriesLabel: string | undefined;
  public readonly productCurrency: string | undefined;
  public readonly metaTitle: string | undefined;
  public readonly metaDescription: string | undefined;
  public readonly isNew: boolean = false;
  public readonly isComingSoon: boolean = false;
  public readonly isSale: boolean = false;
  public readonly hasFastDelivery: boolean = false;
  public readonly comingSoonLabel: string | undefined;
  public readonly description: string | undefined;
  public readonly currencySign: string | undefined;
  public readonly series: string;
  public readonly family: string;
  public readonly familyLabel: string | undefined;
  public readonly price: number | undefined;
  public readonly discountPrice: number | undefined;
  public readonly discountPercent: number | undefined;
  public readonly pdpActionBoxLinksData: PdpActionBoxLink | undefined;
  public readonly priceText: string;
  public readonly previousPriceText: string | undefined;
  public readonly pricePrefix: string;
  public readonly pricePrefixAttentionText: string | undefined;
  public readonly isOutOfStock: boolean;
  public readonly isVisionSystemConfigurable: boolean;
  public readonly isConfigurableProduct: boolean;
  public readonly showPrice: boolean;
  public readonly showAddToCartButton: boolean;
  public readonly showRequestCartButton: boolean;
  public readonly showCSV: boolean;
  public readonly showShippingTime: boolean;
  public readonly hasRelatedProducts: boolean;
  public readonly brandData: {
    image: string[];
    label: {
      label: string;
      value: string[];
    };
    magento_option_id: string;
  }[];
  public readonly conformityEntityData: [];
  public readonly interface?: string;
  public readonly testCameraAvailable: boolean;
  public readonly productLine: string | undefined;
  public readonly seoIndex: string | undefined;
  public readonly seoFollow: string | undefined;
  public readonly technicalDrawing: TechnicalDrawing;
  public readonly discontinuationStatus: string | undefined;
  public readonly followingProducts: CrossSellProductFragment[];
  public readonly discontinuationLinks: {
    ctf_entity_id?: {
      label: string;
      value: string[];
    };
    parameters?: {
      label: string;
      value: string[];
    };
    magento_option_id?: string;
  }[];
  public readonly accessoryCategory: string | undefined;
  public readonly accessoryType: string | undefined;
  /**
   * XXX: do we want to expose custom attributes?
   */
  public readonly customAttributes: ReadonlyArray<CustomAttributeFragment>;
  public readonly isDiscontinued: boolean;
  public readonly bundleUrlKey: string | undefined;

  private readonly mappedCustomAttributes: IndexedCustomAttributes;

  static build(
    product: CommonProductFragment & Partial<ProductDetailFragment>,
    translations: Partial<Record<string, string>> = {},
    locale = 'en-us',
    isTextTeaserCardList = false
  ) {
    return new DecoratedProduct(
      product,
      translations,
      locale,
      isTextTeaserCardList
    );
  }

  /**
   * TODO: move most of this to server side.
   * Make independent from Translations and create a class on client for
   * list and detail page use.
   */
  constructor(
    /**
     * @deprecated Direct access to the product should not be necessary.
     * Derive a property instead!
     */
    public readonly product: CommonProductFragment &
      Partial<ProductDetailFragment>,
    private readonly translations: Partial<Record<string, string>>,
    private readonly locale: string,
    private readonly isTextTeaserCardList: boolean
  ) {
    this.id = product.uid;
    this.urlKey = product.url_key ?? undefined;
    this.sku = product.sku ?? undefined;
    this.isOutOfStock = product.stock_status !== STATE_IN_STOCK;

    this.customAttributes = (product?.custom_attributes ?? []).filter(
      isDefined
    ) as CustomAttributeFragment[];

    this.mappedCustomAttributes = getIndexCustomAttributes(
      this.customAttributes
    );

    this.isVisionSystemConfigurable = extractBool(
      getCustomAttributeValue(this.mappedCustomAttributes[SHOW_VSC], {
        label: false
      }),
      false
    );

    this.showAddToCartButton = extractBool(
      getCustomAttributeValue(
        this.mappedCustomAttributes[ECOMMERCE_SHOW_ADDTOCARTBUTTON],
        { label: false }
      ),
      true
    );

    this.showRequestCartButton = extractBool(
      getCustomAttributeValue(
        this.mappedCustomAttributes[ECOMMERCE_SHOW_REQUESTCARTBUTTON],
        { label: false }
      ),
      true
    );

    this.shippingTime = extractNumber(
      getCustomAttributeValue(this.mappedCustomAttributes[SHIPPING_TIME_CODE]),
      0
    );

    this.productFeaturesAttributes = failSafeParseJSON(
      getCustomAttributeValue(
        this.mappedCustomAttributes[HARDWARE_FEATURES_DATA]
      ),
      []
    );

    //depracted
    this.distributors = getCustomAttributeArrayValue(
      this.mappedCustomAttributes[DISTRIBUTORS]
    );
    //deprecated
    this.distributorsData = getCustomAttributeArrayValue(
      this.mappedCustomAttributes[DISTRIBUTORS_DATA]
    );

    this.channelPartners = getCustomAttributeArrayValue(
      this.mappedCustomAttributes[CHANNEL_PARTNERS]
    );

    this.channelPartnersData = failSafeParseJSON<[], []>(
      getCustomAttributeValue(
        this.mappedCustomAttributes[CHANNEL_PARTNERS_DATA]
      ),
      []
    );

    this.productDescription = getCustomAttributeValue(
      this.mappedCustomAttributes[SEO_HEADLINE]
    );

    this.technicalDrawing = new TechnicalDrawing(
      (() => {
        const technicalDrawing = Object.values(
          failSafeParseJSON<{}, {}>(
            getCustomAttributeValue(
              this.mappedCustomAttributes[TECHNICAL_DRAWING]
            ),
            {}
          )
        )[0] as {} | null;

        if (JSON.stringify(technicalDrawing) === '{}') {
          return null;
        }
        return technicalDrawing;
      })()
    );

    this.isNew = extractBool(
      getCustomAttributeValue(this.mappedCustomAttributes[IS_NEW], {
        label: false
      }),
      false
    );

    this.isComingSoon = extractBool(
      getCustomAttributeValue(this.mappedCustomAttributes[IS_COMING_SOON], {
        label: false
      }),
      false
    );

    this.hasFastDelivery = getFastDelivery(
      product,
      this.mappedCustomAttributes
    );

    this.comingSoonLabel = getCustomAttributeValue(
      this.mappedCustomAttributes[COMING_SOON_LABEL]
    );

    this.productBrand = getCustomAttributeValue(
      this.mappedCustomAttributes[BRAND]
    );

    this.productTeaserText = getCustomAttributeValue(
      this.mappedCustomAttributes[TEASER_TEXT]
    );

    this.bundleUrlKey = getCustomAttributeValue(
      this.mappedCustomAttributes[IS_IN_BUNDLE]
    );

    this.metaTitle = product.meta_title ?? undefined;

    this.metaDescription =
      getCustomAttributeValue(
        this.mappedCustomAttributes[VARIANT_BASE_META_DESCRIPTION]
      ) ??
      product.meta_description ??
      undefined;

    this.description =
      getCustomAttributeValue(this.mappedCustomAttributes[VARIANT_BASE_TEXT]) ??
      product.description?.html ??
      undefined;

    this.name = product.name ?? undefined;

    this.modelName =
      getCustomAttributeValue(this.mappedCustomAttributes[MODEL_NAME]) ??
      (this.product.name as string);

    this.category = product.categories?.[0]?.name ?? undefined;
    this.categoryId = product.categories?.[0]?.uid ?? undefined;

    const isDocumentationAvailable = extractBool(
      getCustomAttributeValue(
        this.mappedCustomAttributes[DOCUMENTATION_AVAILABLE],
        {
          label: false
        }
      ),
      false
    );
    const documentationUrl = getCustomAttributeValue(
      this.mappedCustomAttributes[DOCUMENTATION_URL]
    );

    const documentationUrlSlug = getCustomAttributeValue(
      this.mappedCustomAttributes[DOCUMENTATION_URL_SLUG]
    );

    if (isDocumentationAvailable && documentationUrl) {
      this.productDocumentationLink = documentationUrl;
      this.productDocumentationLinkSlug = documentationUrlSlug;
    }

    this.seriesLabel = getCustomAttributeValue(
      this.mappedCustomAttributes[CAMERA_SERIES_CODE]
    );

    this.discontinuationStatus = getCustomAttributeValue(
      this.mappedCustomAttributes[DISCONTINUATION_STATUS],
      { label: true }
    );
    this.isDiscontinued =
      this.discontinuationStatus === DISCONTINUATION_STATUS_DISCONTINUED;

    this.brandData = failSafeParseJSON<[], []>(
      getCustomAttributeValue(this.mappedCustomAttributes[BRAND_DATA]),
      []
    );
    this.conformityEntityData = failSafeParseJSON<[], []>(
      getCustomAttributeValue(
        this.mappedCustomAttributes[CONFORMITY_ENTITY_DATA]
      ),
      []
    );
    this.discontinuationLinks = failSafeParseJSON<[], []>(
      getCustomAttributeValue(
        this.mappedCustomAttributes[DISCONTINUATION_ADDITIONAL_LINKS_DATA]
      ),
      []
    );
    this.pdpActionBoxLinksData = failSafeParseJSON<PdpActionBoxLink[]>(
      getCustomAttributeValue(
        this.mappedCustomAttributes[PDP_ACTION_BOX_LINKS_DATA]
      )
    )?.[0];

    this.followingProducts =
      this.product?.crosssell_products?.filter(isDefined) ?? [];

    this.interface = getCustomAttributeValue(
      this.mappedCustomAttributes[INTERFACE]
    );

    this.familyLabel = getCustomAttributeValue(
      this.mappedCustomAttributes[FAMILY]
    );

    this.cameraCategory = getCustomAttributeFirstValue(
      this.mappedCustomAttributes[CAMERA_CATEGORY]
    );

    this.accessoryCategory = getCustomAttributeFirstValue(
      this.mappedCustomAttributes[ACCESSORY_CATEGORY]
    );

    this.accessoryType = getCustomAttributeFirstValue(
      this.mappedCustomAttributes[ACCESSORY_TYPE]
    );

    this.series = this.seriesLabel ?? this.productBrand ?? '';
    this.family = this.familyLabel
      ? this.productBrand + ' ' + this.familyLabel
      : this.series;
    this.isConfigurableProduct = 'configurable_options' in product;
    this.showCSV =
      this.isVisionSystemConfigurable && !this.isConfigurableProduct;

    this.hasRelatedProducts = (product.related_products ?? []).length > 0;

    this.showShippingTime = !(
      (this.shippingTime ?? 0) <= 0 ||
      this.shippingTime >= 180 ||
      this.isOutOfStock ||
      (!this.showAddToCartButton && !this.showRequestCartButton)
    );
    this.shippingTimeText = createShippingTimeText({
      shippingTime: this.shippingTime,
      showShippingTime: this.showShippingTime,
      fallbackLabel: translations['pdp.shippingTimeFallback.label'],
      labelTemplateMulti: translations['pdp.shippingTime.template.multi'],
      labelTemplateSingle: translations['pdp.shippingTime.template.single'],
      labelTemplateMultiDays:
        translations['pdp.shippingTime.template.multi.days']
    });

    // Price related things
    // e.g. "EUR"
    this.productCurrency =
      product?.price_range?.minimum_price?.final_price?.currency ?? '';
    if (this.productCurrency) {
      try {
        // the locale does not matter as only the sign is used.
        this.currencySign = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: this.productCurrency
        })
          .formatToParts(1)
          .find((part) => part.type === 'currency')?.value;
      } catch (_e) {
        // XXX: proper logging? until that, ignore error for now
      }
    }

    this.price =
      product.price_range?.minimum_price?.regular_price?.value ?? undefined;
    this.discountPrice =
      product.price_range?.minimum_price?.final_price?.value ?? undefined;
    this.discountPercent =
      product.price_range?.minimum_price?.discount?.percent_off ?? undefined;
    this.isSale =
      this.discountPrice != null && this.discountPrice !== this.price;

    this.showPrice = !(
      (this.price ?? 0) < 0.01 ||
      !extractBool(
        getCustomAttributeValue(this.mappedCustomAttributes[SHOW_PRICE], {
          label: false
        }),
        true
      )
    );

    this.priceText = this.isSale
      ? this.createPriceText(this.discountPrice)
      : this.createPriceText(this.price);
    this.previousPriceText = this.isSale
      ? this.createPriceText(this.price)
      : undefined;

    this.pricePrefixAttentionText =
      this.discountPercent && this.discountPercent > 1
        ? `-${this.discountPercent.toFixed(0)}%`
        : undefined;

    this.pricePrefix = translations['pdp.netPrice.label'] ?? '';

    this.testCameraAvailable = extractBool(
      getCustomAttributeValue(
        this.mappedCustomAttributes[TESTCAMERA_AVAILABLE],
        { label: false }
      ),
      false
    );

    this.productLine = getCustomAttributeValue(
      this.mappedCustomAttributes[CAMERA_PRODUCT_LINE]
    );

    this.seoIndex = getCustomAttributeValue(
      this.mappedCustomAttributes[SEO_INDEX]
    );
    this.seoFollow = getCustomAttributeValue(
      this.mappedCustomAttributes[SEO_FOLLOW]
    );
  }

  renderFeatureAttributes(): {
    key: string;
    items: ReadonlyArray<Attribute>;
  }[] {
    const features: Attribute[] = this.productFeaturesAttributes.map(
      (p) =>
        ({
          key: ensureArray(p.featuregruppe?.value)[0],
          label: ensureArray(p.link?.value)[0] ?? '',
          value:
            this.translations[
              `pdp.feature.${ensureArray(p.label?.value)[0]}.label`
            ] ??
            ensureArray(p.label?.value)[0] ??
            ''
        } satisfies Attribute)
    );

    const groupedFeature: Record<string, Attribute[]> = features.reduce(
      (prevValue: { [key: string]: any }, attr) => ({
        ...prevValue,
        [attr.key]: [...(prevValue[attr.key] || []), attr]
      }),
      {}
    );

    const preparedForTable = Object.entries(groupedFeature)
      .map(([key, items]) => ({
        key,
        items: items.sort((a, b) =>
          a.value.toString().localeCompare(b.value.toString())
        )
      }))
      .sort((a: { key: string }, b: { key: string }) =>
        a.key.localeCompare(b.key)
      );

    return preparedForTable.filter(
      (x) => x.key !== 'undefined' && x.items.length !== 0
    );
  }

  getProductImage(
    options: GenerateImageObjectOptions = {}
  ): MediaItemInterface | undefined {
    return getProductImage(this.product, options)[0]?.media;
  }

  private shouldShowDescriptionInListEntry(
    specificationsMapping: PdpConfiguration
  ) {
    return filteredSpecificationMappings(
      this.mappedCustomAttributes,
      specificationsMapping.specificationMappings,
      specificationsMapping.variables,
      specificationsMapping.attributeSets,
      this.product?.attribute_set_id?.toString() ?? ''
    ).some((x) => x.showDescriptionInList);
  }

  asListEntry(
    specificationsMapping: PdpConfiguration,
    formValues: ProductFiltersModel,
    createDetailLink: (p: DecoratedProduct) => string,
    productListType: 'Specifications' | 'Discontinued' = 'Specifications',
    discontinuedSpecs: string[] = []
  ): ProductCardProps {
    const attributes = this.buildAttributesForListEntry(
      specificationsMapping,
      productListType,
      discontinuedSpecs
    );

    return {
      showAttributes: true,
      link: this.createDetailPageLink(createDetailLink, formValues),
      badgeText: this.buildBadgeText(),
      product: {
        id: this.id ?? '',
        name: this.series !== '' ? this.series : ' ',
        variantName: this.modelName ?? '',
        description: this.shouldShowDescriptionInListEntry(
          specificationsMapping
        )
          ? this.productDescription ?? ' '
          : ' ',
        // XXX: wird nirgendwo angezeigt?
        price: this.discountPrice ?? this.price ?? -1,
        priceText: productListType !== 'Discontinued' ? this.priceText : '',
        pricePrefixAttentionText: this.showPrice
          ? this.pricePrefixAttentionText
          : undefined,
        previousPriceText: this.showPrice ? this.previousPriceText : undefined,
        pricePrefix: productListType !== 'Discontinued' ? this.pricePrefix : '',
        attributes,
        media: this.getProductImage({
          width: 800,
          aspectRatio: [4, 3],
          fit: this.isTextTeaserCardList ? 'pad' : undefined,
          bgColor: this.isTextTeaserCardList ? 'rgb:ffffff' : undefined
        })
      } satisfies Product
    };
  }

  buildBadgeText(): string {
    if (this.isSale && this.showPrice) {
      return this.translations['productsList.product.badge.isSale'] ?? '';
    }

    if (this.isNew) {
      return this.translations['productsList.product.badge.isNew'] ?? '';
    }

    if (this.isComingSoon) {
      if (this.comingSoonLabel) {
        return (
          this.translations['productsList.product.badge.isComingSoon'] +
          ' ' +
          this.comingSoonLabel
        );
      }

      return this.translations['productsList.product.badge.isComingSoon'] ?? '';
    }

    return '';
  }

  createPriceText(overwritePrice = 0): string {
    return createPriceText({
      showPrice: this.showPrice,
      locale: this.locale,
      price: this.product?.price_range?.minimum_price?.final_price,
      overwritePrice,
      onRequestLabel: this.translations['pdp.priceOnRequest.label'],
      prefix:
        'variants' in this.product && this.product.variants
          ? this.translations['pdp.netPrice.variantPrefix'] + ' '
          : undefined
    });
  }

  buildAttributesForListEntry(
    specificationsMapping: PdpConfiguration,
    productListType: 'Specifications' | 'Discontinued',
    discontinuedSpecCodes: string[]
  ) {
    if (productListType === 'Specifications') {
      const variants: ConfigurableVariant[] =
        'variants' in this.product
          ? (this.product.variants as ConfigurableVariant[])
          : ([] as ConfigurableVariant[]);

      return createHighlightedSpecifications(
        specificationsMapping,
        this.product?.custom_attributes as CustomAttribute[],
        this.product?.attribute_set_id?.toString() ?? '',
        this.locale as LOCALE_CODE,
        'highlightedInList',
        variants
          ?.map(
            (entry: ConfigurableVariant) =>
              entry?.product?.custom_attributes as CustomAttribute[]
          )
          ?.filter(isDefined) ?? []
      );
    }

    const indexedCustomAttributes = getIndexCustomAttributes(
      this.product?.custom_attributes as CustomAttribute[]
    );

    return discontinuedSpecCodes
      .map((code) => {
        if (!isDefined(indexedCustomAttributes[code])) {
          return undefined;
        }

        return extractLabelValueFromCustomAttribute(
          indexedCustomAttributes[code],
          this.locale as LOCALE_CODE
        );
      })
      .filter(isDefined) as Attribute[];
  }

  createDetailPageLink(
    createProductDetailLink: (p: DecoratedProduct) => string,
    filters: ProductFiltersModel
  ): HyperlinkInterface {
    const forwardedValues = createForwardedValues(filters);
    return {
      uri: {
        path: createProductDetailLink(this),
        query: Object.keys(forwardedValues)
          .filter((key: string) => key.startsWith('utm_'))
          .reduce((obj: Record<string, any>, key) => {
            obj[key] = forwardedValues[key];
            return obj;
          }, {} as Record<string, any>)
      },
      // title is missing in the link type
      ...{ title: this.name },
      external: false
    };
  }

  /**
   * PDP Only
   */
  getProductSpecifications(link?: string): Group[] {
    if (this.isDiscontinued) {
      return createSpecifications(
        (this.product.attribute_set?.filter(
          (as) =>
            isDefined(as) &&
            as.group?.attribute_group_code === 'general_information'
        ) ?? []) as AttributeSetFragment[],
        'table',
        this.locale as LOCALE_CODE
      );
    }

    const groups = createSpecifications(
      this.product.attribute_set?.filter(isDefined) ?? [],
      'table',
      this.locale as LOCALE_CODE,
      link
    );

    if (this.technicalDrawing.isValid()) {
      const altText = [
        this.translations['pdp.specs.technical_drawing.label'],
        this.name
      ]
        .filter(isDefined)
        .join(' ')
        .trim();

      groups.push({
        type: 'images',
        title:
          this.translations['pdp.specs.technical_drawing.label'] ??
          'Technical Drawing',
        images: [
          {
            mediaContent: {
              lazyload: true,
              cols: [12, 3],
              media: {
                sizes: '',
                mimeType: 'image/svg',
                src: this.technicalDrawing.getTechnicalDrawingLink(),
                srcset: this.technicalDrawing.getTechnicalDrawingLink(),
                alt:
                  altText ||
                  this.technicalDrawing.getLabel() ||
                  'Technical Drawing'
              }
            },
            downloadLink: this.technicalDrawing.getCadLink() ?? '',
            downloadLinkText:
              this.translations[
                'pdp.specs.technical_drawing.cad_download_link.label'
              ] ?? 'Download CAD File'
          }
        ]
      });
    }

    if (this.brandData.length > 0) {
      const brandData = this.brandData.filter(isDefined);

      groups.push({
        type: 'table',
        title: this.translations['pdp.specs.brand.label'] ?? 'Brand',
        specs: [
          {
            label: ensureArray(brandData[0]?.label?.value)[0],
            value: ''
          }
        ]
      });
    }

    if (this.conformityEntityData.length > 0) {
      groups.push({
        type: 'checklist',
        title: this.translations['pdp.specs.conformity.label'] ?? 'Conformity',
        // @ts-ignore broken type in hypercode component
        items: this.conformityEntityData
          .map(
            (x: Pick<PdpActionBoxLink, 'label'> | undefined) =>
              ensureArray(x?.label?.value)[0] ?? undefined
          )
          .filter(isDefined)
      });
    }

    return groups;
  }

  /**
   * PDP Only
   */
  getGroupedRelatedProducts(
    pdpMapping: PdpConfiguration
  ): RelatedProductGroupModel[] {
    const idToConstantAttributeSets = Object.keys(
      pdpMapping.attributeSets
    ).reduce((ret: Record<string, string>, key: string) => {
      const attributeSet = pdpMapping.attributeSets[key];
      if (attributeSet) {
        ret[attributeSet] = key;
      }
      return ret;
    }, {});

    const attributeSetId = this.product.attribute_set_id;
    if (
      !(
        typeof attributeSetId === 'number' &&
        idToConstantAttributeSets[attributeSetId]
      )
    ) {
      return [];
    }
    const attributeSetConst: string = idToConstantAttributeSets[attributeSetId];
    if (!(attributeSetConst in pdpMapping.relatedProductMapping)) {
      return [];
    }

    const mapping = pdpMapping.relatedProductMapping[attributeSetConst];
    const variables = pdpMapping.variables;
    const relatedProds = this.product.related_products?.filter(isDefined);
    return mapping.map((m) => {
      const sets = m.sets.map(
        (attributeSetConstant: string) =>
          pdpMapping.attributeSets[attributeSetConstant]
      );
      const title =
        m.dictionary ??
        ((m.sets ?? []).length === 1
          ? `pdp.relatedproducts.tab.${m.sets[0]}`
          : m.sets.join('_and_'));

      let queryFilter: ProductAttributeFilterInput = {};
      if (m.filter) {
        queryFilter = cloneDeep(m.filter);
        let key: keyof ProductAttributeFilterInput;
        Object.entries(queryFilter).map((e) =>
          e.map((subE) => {
            if (typeof subE === 'string') {
              key = subE as keyof ProductAttributeFilterInput;
            }
            if (subE !== null && typeof subE === 'object') {
              if ('eq' in subE && subE.eq) {
                subE.eq = variables[subE.eq] ?? subE.eq;
              }
              if ('match' in subE && subE.match) {
                subE.match = variables[subE.match] ?? subE.match;
              }
              if ('in' in subE && Array.isArray(subE.in)) {
                subE.in = (subE.in ?? [])
                  .filter(isDefined)
                  .map((listItem) => variables[listItem] ?? listItem)
                  .filter(isDefined);
              }
              queryFilter[key] = subE;
            }
            return subE;
          })
        );
      }

      return {
        title,
        groupTitle: title,
        queryFilter,
        products: relatedProds?.filter((p) => {
          if (!p?.attribute_set_id) {
            return false;
          }
          return sets.includes(p?.attribute_set_id);
        })
      } as RelatedProductGroupModel;
    });
  }

  /**
   * PDP Only
   */
  createProductEnrichmentRules(): ProductEnrichmentRules {
    return {
      productSkus: this.sku ? [this.sku] : [],
      productUrlKeys: this.urlKey ? [this.urlKey] : [],
      productSeries: this.seriesLabel ? [this.seriesLabel] : [],
      cameraProductFamily: this.familyLabel ? [this.familyLabel] : [],
      productAccessoryCategories: this.accessoryCategory
        ? [this.accessoryCategory]
        : [],
      productCategories: this.cameraCategory ? [this.cameraCategory] : [],
      accessoryType: this.accessoryType ? [this.accessoryType] : []
    };
  }

  /**
   * PDP Only
   */
  getCSVLink(
    conf?: PdpConfiguration | null,
    vscPageLink?: HyperlinkInterface | null
  ): HyperlinkInterface | undefined {
    if (!conf || !vscPageLink || !this.showCSV) {
      return;
    }
    const csvComponent = this.getCSVComponent(conf);
    if (csvComponent && this.sku) {
      return {
        ...vscPageLink,
        uri: `${vscPageLink.uri}?component=${csvComponent}#sku=${this.sku}`
      };
    }
  }

  private getCSVComponent(conf: PdpConfiguration): string | null {
    const map: { [index: string]: string } = {
      lighting: 'lighting_advisor',
      '3d_camera': 'camera_selector',
      area_scan_camera: 'camera_selector',
      line_scan_camera: 'camera_selector',
      lens: 'lens_selector',
      acquisition_cards: 'acquisition_card_selector'
    };
    const attributeSetMap = flipAttributeSetMapping(conf.attributeSets);
    const attributeSetConstant = this.product.attribute_set_id
      ? attributeSetMap[this.product.attribute_set_id]
      : null;

    if (isDefined(attributeSetConstant) && attributeSetConstant in map) {
      return map[attributeSetConstant];
    }
    return null;
  }
}

export type IndexedCustomAttributes = {
  [key: string]: CustomAttributeFragment;
};

export function getIndexCustomAttributes(
  customAttributes: ReadonlyArray<CustomAttributeFragment>
): IndexedCustomAttributes {
  const mappedCustomAttributes: IndexedCustomAttributes = {};
  customAttributes.filter(isDefined).forEach((attr) => {
    if (attr.attribute_metadata?.code) {
      mappedCustomAttributes[attr.attribute_metadata.code] = attr;
    }
  });
  return mappedCustomAttributes;
}

function getFastDelivery(
  product: CommonProductFragment & Partial<ProductDetailFragment>,
  mappedCustomAttributes: IndexedCustomAttributes
) {
  if ('variants' in product) {
    return (
      product.variants?.some((variant) => {
        if (
          variant &&
          variant?.product &&
          'custom_attributes' in variant.product
        ) {
          const product: ConfigurableProduct =
            variant.product as ConfigurableProduct;
          const mappedCustomAttributes = getIndexCustomAttributes(
            (product?.custom_attributes ?? []).filter(
              isDefined
            ) as CustomAttributeFragment[]
          );
          return extractBool(
            getCustomAttributeValue(
              mappedCustomAttributes[FASTDELIVERY_AVAILABLE],
              {
                label: false
              }
            ),
            false
          );
        }
        return false;
      }) ?? false
    );
  } else {
    return extractBool(
      getCustomAttributeValue(mappedCustomAttributes[FASTDELIVERY_AVAILABLE], {
        label: false
      }),
      false
    );
  }
}

function extractBool(
  attributeValue: string | undefined,
  fallback: boolean
): boolean {
  if (attributeValue) {
    return attributeValue === '1';
  }
  return fallback;
}

function extractNumber(
  attributeValue: string | undefined,
  fallback: number
): number {
  return parseInt(attributeValue ?? String(fallback), 10);
}

export class TechnicalDrawing {
  private _data:
    | {
        label?: string;
        cad_download_center_link?: string;
        technical_drawing_medialink?: string;
      }
    | null
    | undefined;

  constructor(
    data:
      | {
          label?: string;
          cad_download_center_link?: string;
          technical_drawing_medialink?: string;
        }
      | null
      | undefined
  ) {
    this._data = data;
  }

  public isValid(): boolean {
    return typeof this._data === 'object' && isDefined(this._data?.label);
  }

  public getLabel(): string | undefined {
    return this._data?.label;
  }

  public getCadLink(): string | undefined {
    return this._data?.cad_download_center_link;
  }

  public getTechnicalDrawingLink(): string | undefined {
    return this._data?.technical_drawing_medialink;
  }
}
